import React from 'react';

import './assets/styles/global.css';
import Routes from './routes';


function App() {
  return (
    <Routes/>
  );
}

export default App;
